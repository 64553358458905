import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Logo } from "../Logo/Logo";
import { Title } from "../Title/Title";
import { Description } from "../Description/Description";
import Components from "./utils/components";
import { Footer } from "../Footer/Footer";
import "../../assets/css/index.css";
import { DropdownLanguages } from "./Components/DropdownLanguages/DropdownLanguages";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
import { AlertError } from "./Components/AlertError/AlertError";

export const FormChat = (props) => {
  const { styles, user, medias, error, errorAlert, setErrorAlert } = props;
  const [scrollY, setScrollY] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    let containers = document.getElementsByClassName("chat-header");
    if (containers.length > 0) {
      let container = containers[0];
      let heightContainer = container.offsetHeight;
      setContainerHeight(heightContainer);
    }
    if (
      props.user.pixel_facebook
    ) {
      ReactPixel.init(props.user.pixel_facebook);

      ReactPixel.pageView();

    }
    if (
      props.user.pixel_analitycs
    ) {
      ReactGA.initialize(props.user.pixel_analitycs);

    }

  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      setScrollY(window.scrollY);
    });
  }, []);
   return (
    <>
      <div className="containerTemplateBackground" />
      <Container
        fluid
        translate="no"
        className="containerTemplate contentContainer urbanist"
      >
        <div className="chat colorTextVar">
          <div className="chat-header colorTextHeader backgroundColorHeader">
            <div className="divHeaderFormChat">
              <div className="container-logo-title">
                <Logo styles={styles} user={user} medias={medias} />
                <Title user={user} showWriting={props.showWriting} />
                <AlertError error={error} errorAlert={errorAlert} setErrorAlert={setErrorAlert} />



              </div>
              <DropdownLanguages
                language={props.language}
                setLanguage={props.setLanguage}
              />
            </div>
            <Description user={user} scrollY={scrollY}></Description>

          </div>
          <div
            className="chat-form border-color pdn20 borderColorVar backgroundColorAssistant"
            style={{
              marginTop: `${containerHeight}px`,
            }}
          >
            <div className="App">
              {props?.data?.map(
                (block) => block.conditional && Components(block, props)
              )}
            </div>

            {user.check_watermark === 0 ? (
              <div className="divGenericFooterTemplate" id="screens">
                <Footer id="view" props={props} />
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    </>
  );
};
