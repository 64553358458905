import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { ButtonAnotherQuery } from "./ButtonAnotherQuery";
import { saveAnotherQuery } from "../../../../helpers/helpers";
import { IconArrowRight } from "../Icons/Icon-arrow-right/IconArrowRight";
import { backComponent, nextComponent } from "../../utils/nextComponent";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { Email } from "../Email/Email";

export const AnotherQuery = ({ props, block }) => {
  const [t] = useTranslation("global");
  const [textAreaCount, changeTextAreaCount] = useState(0);
  const [messageLoading, setMessageLoading] = useState(null);
  const myRef2 = useRef(null);
  let id_user = props.user.id_user;
  useEffect(() => {
    if (block.conditional) {
      setMessageLoading(true);
      setTimeout(() => {
        setMessageLoading(false);
        if (props.user.check_tattoo === 1) {
          executeScroll();
        }
        if (props.arrayComponentServices !== 0) {
          executeScroll();
        }
      }, 1000);
    }
  }, []);

  const handleChangeTextArea = (e) => {
    const { value } = e.target;
    changeTextAreaCount(value.length);
    props.setQuery(value);
    if (value.length < 3) {
      backComponent(props, block, 1);
    }
    if (value === "") {
      props.setCheckTermsAnotherQuery(false);
    }
  };

  const handleChange = (e) => {
    let query = props.query;
    props.setCheckTermsAnotherQuery(true);
    saveAnotherQuery({ id_user, query });
  };

  const next = () => {
    props.query.length >= 3 && nextComponent(props, block, 1);
  };

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const popover = (
    <Popover id="popover-basic" className="custom-popover">
      <div className="div-button-popover">
        <button onClick={() => props.setShowPopover(false)}>X</button>
      </div>
      <Popover.Body>
        <p className="terms-conditions">
          {t("popoverTermsAndConditions.text1")}
        </p>
        <p className="conditions">{t("popoverTermsAndConditions.text2")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text3")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text4")}
          <a href="https://www.inkup.io" target="_blank" rel="noreferrer">
            www.inkup.io
          </a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text5")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text6")}
          <a href="https://inkup.io/tyc" target="_blank" rel="noreferrer">
            https://inkup.io/tyc
          </a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text7")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text8")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text9")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text10")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text11")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text12")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text13")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text14")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text15")}</p>
        <p className="text-grey"> {t("popoverTermsAndConditions.text16")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text17")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text18")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text19")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text20")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text21")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text22")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text23")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text24")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text25")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text26")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text27")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text28")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text29")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text30")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text30a")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text31")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text32")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text33")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text34")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text35")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text36")}</p>
        <p className="text-grey">
          {t("popoverTermsAndConditions.text37")}
          <a href="mailto:info@inkup.io">info@inkup.io</a>
        </p>
        <p className="text-grey">{t("popoverTermsAndConditions.text38")}</p>
        <p className="conditions">{t("popoverTermsAndConditions.text39")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text40")}</p>
        <p className="text-grey">{t("popoverTermsAndConditions.text41")}</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {/* {messageLoading === true ? (
        <MessageLoading imgLogoInitial={props.initailOrImage} />
      ) : ( */}
      {props.arrayComponentServices.length === 0 && (
        <>
          {props.user.check_tattoo === 0 && (
            <Email props={props} block={block} />
          )}
          {messageLoading === false && (
            <>
              <Form.Group>
                <div className="divDescriptionFormChat" ref={myRef2}>
                  <Form.Control
                    id="idTextAreaDescriptionFormChat"
                    className="inputTextarea2 inputTextareaAnotherQuery sizeTextareaFormChat urbanist"
                    as="textarea"
                    maxLength={1000}
                    placeholder={t("anotherQuery.textArea")}
                    onChange={handleChangeTextArea}
                    required
                    disabled={
                      props.user.check_tattoo === 0 &&
                      props.disabledTextAreaAnotherQuery &&
                      props.arrayComponentServices.length === 0
                    }
                    // autoFocus={true}
                    autoFocus={props.user.check_tattoo === 1 && true}
                  />
                  <p className="text-secondary numbersTextareaFormChat">
                    {textAreaCount}/1000
                  </p>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Group
                  className="divTermsFormChat paddingButtonCheck"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check.Input
                    id="idCheckTermsFormChat"
                    className="inputRadioFormChat sizeInputTermsFormChat2"
                    type="checkBox"
                    onChange={
                      props.query.length >= 3 ? handleChange : undefined
                    }
                    checked={props.checkTermsAnotherQuery}
                    readOnly
                    disabled={
                      props.user.check_tattoo === 0 &&
                      props.disabledTextAreaAnotherQuery
                    }
                  />
                  <span className="pTerminosFormChat2 ">
                    {t("signup.accept")} {t("signup.terms")}
                  </span>
                </Form.Group>

                <div
                  className={`divButtonFormTemplate ${
                    props.checkTermsAnotherQuery ? "bounce" : ""
                  }`}
                >
                  <ButtonAnotherQuery props={props} />
                </div>

                <Form.Group
                  className="divTermsFormChat"
                  controlId="formBasicCheckbox"
                >
                  <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={popover}
                    show={props.showPopover}
                    rootClose={true}
                  >
                    <Button
                      className="spamTerminosFormChat2"
                      variant="success"
                      onClick={() => props.setShowPopover(!props.showPopover)}
                    >
                      {t("info.showTerms")}
                    </Button>
                  </OverlayTrigger>
                </Form.Group>
              </Form.Group>
            </>
          )}
        </>
      )}

     
      {props.arrayComponentServices.length !== 0 && messageLoading === true ? (
        <MessageLoading imgLogoInitial={props.initailOrImage} />
      ) : (
        props.arrayComponentServices.length !== 0 &&
        messageLoading === false && (
          <>
            <Form.Group>
              <div className="divDescriptionFormChat" ref={myRef2}>
                <Form.Control
                  id="idTextAreaDescriptionFormChat"
                  className="inputTextarea2 inputTextareaAnotherQuery sizeTextareaFormChat urbanist"
                  as="textarea"
                  maxLength={1000}
                  placeholder={t("anotherQuery.textArea")}
                  onChange={handleChangeTextArea}
                  required
                  // disabled={
                  //   props.user.check_tattoo === 0 &&
                  //   props.disabledTextAreaAnotherQuery
                  // }
                  autoFocus={true}
                  // autoFocus={props.user.check_tattoo === 1 && true}
                />
                <p className="text-secondary numbersTextareaFormChat">
                  {textAreaCount}/1000
                </p>
              </div>
            </Form.Group>
            <div className="divButtonSendImageImagesTatto">
              <Button
                className="buttonSendImageImagesTatto2"
                disabled={props.query.length < 3}
                onClick={() => next()}
              >
                {t("description.continue")}
                <IconArrowRight />
              </Button>
            </div>
          </>
        )
      )}
    </>
  );
};