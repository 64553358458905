import React from "react";
import { Welcome } from "../Components/Welcome/Welcome";
import { Services } from "../Components/Services/Services";
import { Employeds } from "../Components/Employeds/Employeds";
import { Name } from "../Components/Name/Name";
import { EmailPhone } from "../Components/Email&Phone/Email&Phone";
import { Phone } from "../Components/Phone/Phone";
import { Email } from "../Components/Email/Email";
import { Size } from "../Components/Size/Size";
import { Color } from "../Components/Color/Color";
import { Styles } from "../Components/Styles/Styles";
import { SkinTone } from "../Components/SkinTone/SkinTone";
import { BodyPart } from "../Components/BodyPart/BodyPart";
import { Description } from "../Components/Description/Description";
import { Images } from "../Components/Images/Images";
import { City } from "../Components/City/City";
import { Time } from "../Components/Time/Time";
import { Info } from "../Components/Info/Info";
import { Jewelry } from "../Components/Jewelry/Jewelry";
import { OldClient } from "../Components/OldClient/OldClient";
import { FinishText } from "../Components/FinishText/FinishText";
import { ButtonOtherServices } from "../Components/Button/ButtonOtherServices";
import { ImagesMicro } from "../Components/ImagesMicro/Images";
import { EmailOrWhatsapp } from "../Components/EmailOrWhatsapp/EmailOrWhatsapp";
import { Date } from "../Components/Date/Date";

const Components = {
  welcome: Welcome,
  name_budget: Name,
  email_phone_budget: EmailPhone,
  email_budget: Email,
  phone_budget: Phone,
  time_availability: Time,
  check_city: City,
  info: Info,
  images: Images,
  description: Description,
  size: Size,
  budget_color: Color,
  styles: Styles,
  check_skintone: SkinTone,
  body_part: BodyPart,
  service: Services,
  check_query: Services,
  check_laser: Services,
  check_gem_tooth: Services,
  check_microblading: Services,
  check_academy: Services,
  check_barber: Services,
  check_piercing: Services,
  check_micro: Services,
  check_employeds: Employeds,
  type_jewelry: Jewelry,
  old_client: OldClient,
  finish_text: FinishText,
  button_other_services: ButtonOtherServices,
  images_micro: ImagesMicro,
  email_or_whatsapp: EmailOrWhatsapp,
  date_budget: Date
};
const renderComponents = (block, props) => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      key: block._uid,
      block: block,
      props,
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  );
};
export default renderComponents;
