import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { DotsComponents } from "../DotsComponent/DotsComponents";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import imageDefault from "../../../../assets/imagesPiercing/Piercing-jewelry.png";
import {
  nextComponent,
  setFormComponent,
  setFormComponentInfo,
} from "../../utils/nextComponent";
import { IconArrowRightAnimation } from "../Icons/Icon-arrow-right/IconArrowRightAnimation";

export const Jewelry = ({ block, props }) => {
  const { styles } = props;
  const [t] = useTranslation("global");
  const [type_jewelry, setTypeJewelry] = useState("");
  const [messageLoading, setMessageLoading] = useState(null);
  const myRef2 = useRef(null);
  const [dot, setDot] = useState(0);

  const handleTypeJewelry = (data, i) => {
    setTypeJewelry(data.type_name);
    setDot(i);
    setFormComponent(props, block, { type_jewelry: data.type_name });
    setFormComponentInfo(props, block, { type_jewelry: data.type_name });
    nextComponent(props, block);
  };

  useEffect(() => {
    setTimeout(() => {
      setMessageLoading(false);
      props.setShowWriting(false);
      executeScroll();
    }, 1000);
    props.setShowWriting(true);
    setMessageLoading(true);
  }, []);

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <>
          <Form.Group>
            <div className="divGenericLabelFormChat" ref={myRef2}>
              <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
                {t("JewelryPiercing.TextJewelryPiercing")}
                <strong>{t("JewelryPiercing.TextJewelryPiercing1")}</strong>
                {t("JewelryPiercing.TextJewelryPiercing2")}
              </Form.Label>
            </div>
          </Form.Group>
          {props?.typeJewelry?.length > 3 && <IconArrowRightAnimation />}
          <Form.Group className="pFC">
            <div
              id="idBodyPartFormChat"
              className="divGenericBodyPartFormChat divUnsetScrollFormChat"
            >
              {props?.typeJewelry?.map((data, index) => (
                <div key={data.id} className="divScrollBodypart">
                  <div key={data.id}>
                    <Form.Check type="radio" id={`check-api-${data.id}`}>
                      <div
                        // className={
                        //   data?.image
                        //     ? "divBodyPartFormChat styles-images-body-piercing"
                        //     : "backgroundCarrousel divBodyPartFormChat styles-images-body-piercing"
                        // }
                        className="divBodyPartFormChat styles-images-body-piercing"
                        // style={{
                        //   backgroundImage: styles
                        //     ? `linear-gradient(${styles?.color_filter}, ${styles?.color_filter}), url(${data?.image})`
                        //     : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${data?.image})`,
                        //   backgroundColor: data?.image
                        //     ? null
                        //     : styles?.color_background_carrousel &&
                        //       styles?.color_background_carrousel,
                        //   backgroundSize: "cover",
                        // }}
                        style={
                          data.image
                            ? {
                                backgroundImage: styles
                                  ? `linear-gradient(${styles?.color_filter}, ${styles?.color_filter}), url(${data?.image})`
                                  : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${data?.image})`,
                              }
                            : {
                                backgroundImage: styles
                                  ? `linear-gradient(${styles?.color_filter}, ${styles?.color_filter}), url(${imageDefault})`
                                  : `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${imageDefault})`,
                              }
                        }
                      >
                        <Form.Check.Input
                          className="inputRadioFormChat"
                          type="radio"
                          isValid
                          checked={type_jewelry === data.type_name}
                          onChange={() => handleTypeJewelry(data, index)}
                        />
                        <Form.Check.Label className="divTextDefinitionLabelImage  "></Form.Check.Label>
                      </div>
                      <div className="divTextBodyPartPiercing">
                        <p className="colorTextVar m-0">{data.type_name}</p>
                      </div>
                    </Form.Check>
                  </div>
                </div>
              ))}
            </div>
          </Form.Group>
          <DotsComponents dotNumber={props?.typeJewelry?.length} iDot={dot} />
        </>
      )}
    </>
  );
};
