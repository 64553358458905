import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import iconClosedModal from "../../assets/images/iconClosedModal.svg";
import "./modalFooter.css";

export const ModalFooter = ({ show, handleClose, domainName }) => {
  const [t] = useTranslation("global");
  return (
    <>
      <Modal
        dialogClassName="dialog-modal-footer"
        show={show}
        onHide={handleClose}
        contentClassName="modal-content-footer"
      >
        <Modal.Header className="modal-footer-header">
          <Modal.Title className="modal-footer-title">
            {t("modalFooter.title")}
          </Modal.Title>
          <img
            onClick={handleClose}
            className="icon-closed-modal-footer"
            src={iconClosedModal}
            alt="Icono de cierre"
          ></img>
        </Modal.Header>
        <Modal.Body>{t("modalFooter.body")}</Modal.Body>
        <div className="div-buttons-modal">
          {/* <Link to={`//inkup.io/?utm_source=mi.inkup&utm_medium=profile&utm_campaign=footer_logo_cta&utm_content=${domainName}`} target="_blank" rel="noopener noreferrer"> */}
          <a
            // href="https://inkup.io/referral-profile/"
            href={`//inkup.io/referral-profile/?utm_source=my.inkup&utm_medium=profile&utm_campaign=footer_logo_cta&utm_content=${domainName}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              className="button-modal-footer-abandone"
              onClick={handleClose}
            >
              {t("modalFooter.abandone")}
            </Button>
            {/* </Link> */}
          </a>
          <Button
            className="button-modal-footer-not-Abandone"
            onClick={handleClose}
          >
            {t("modalFooter.notAbandone")}
          </Button>
        </div>
      </Modal>
    </>
  );
};
