import React from "react";
import { Dropdown } from "react-bootstrap";
export const DropdownLanguages = ({ language, setLanguage }) => {
  return (
    <Dropdown id="colorTextHeader">
      <Dropdown.Toggle id="colorTextHeader" variant="success"  >
        {language === "es" ? (
          "ES"
        ) : language === "en" ? (
          "EN"
        ) : (
          "DE"
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu id="colorTextHeader">
        <Dropdown.Item id="colorTextHeader" onClick={() => setLanguage("es")}>
          ES
        </Dropdown.Item>
        <Dropdown.Item id="colorTextHeader" onClick={() => setLanguage("en")}>
          EN
        </Dropdown.Item>
        <Dropdown.Item id="colorTextHeader" onClick={() => setLanguage("de")}>
          DE
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
